<template>
    <div class="not-found-box">
        <div class="not-found-header">

            <!-- Lines SVG -->
            <svg class="lines" xmlns="http://www.w3.org/2000/svg" width="1380" height="795" preserveAspectRatio="none" viewBox="0 0 1380 795">
                <defs>
                    <linearGradient id="prefix__a" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stop-color="#FFF" stop-opacity="0"/>
                        <stop offset="100%" stop-color="#31C256"/>
                    </linearGradient>
                </defs>
                <g fill="none" fill-rule="evenodd" stroke="url(#prefix__a)" stroke-dasharray="1 5" stroke-linecap="square" opacity=".5" transform="translate(0 -3)">
                    <path d="M690.5 797.38L690.5 0.62"/>
                    <g>
                        <path d="M.5 797.38L.5.62M46.5 797.38L46.5.62M92.5 797.38L92.5.62M138.5 797.38L138.5.62M184.5 797.38L184.5.62M230.5 797.38L230.5.62M276.5 797.38L276.5.62M322.5 797.38L322.5.62M368.5 797.38L368.5.62M414.5 797.38L414.5.62M460.5 797.38L460.5.62M506.5 797.38L506.5.62M552.5 797.38L552.5.62M598.5 797.38L598.5.62M644.5 797.38L644.5.62" transform="translate(734.5)"/>
                    </g>
                    <path d="M.5 797.38L.5.62M46.5 797.38L46.5.62M92.5 797.38L92.5.62M138.5 797.38L138.5.62M184.5 797.38L184.5.62M230.5 797.38L230.5.62M276.5 797.38L276.5.62M322.5 797.38L322.5.62M368.5 797.38L368.5.62M414.5 797.38L414.5.62M460.5 797.38L460.5.62M506.5 797.38L506.5.62M552.5 797.38L552.5.62M598.5 797.38L598.5.62M644.5 797.38L644.5.62M690.5 797.38L690.5.62"/>
                </g>
            </svg>

            <!-- Cut SVG -->
            <svg class="cut" width="1280px" height="282px" preserveAspectRatio="none" viewBox="0 0 1440 282" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="February-2020/Home-Page-Concepts-v0.0.3" stroke="none" stroke-width="1" fill-rule="evenodd">
                <path
                    d="M-0.000995679525,-0.000949892215 C13.8544559,13.2175266 31.5861505,22.5522039 51.5888236,26.0890734 L51.5888236,26.0890734 L1440,271.588 L1440.00071,281.824036 L0.000709147425,281.824036 Z"
                    id="Combined-Shape"></path>
                </g>
            </svg>

            <div class="not-found-content">
                <img src="/static/images/404/404_logo.png" alt="">
                <div>
                    <img src="/static/images/404/404.png" alt="">
                    <p>{{ notFoundData.title }}</p>
                    <div class="button">
                        <router-link to="/">{{ notFound.button }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notFound',
    data() {
        return {
            notFoundData: {
                title: "Page was not found",
                button: "Back to Home"
            }
        }
    },
    created() {
        let lang = sessionStorage.getItem("lang");
        if (lang === 'en') {
            this.notFoundData = {
                title: "Page was not found",
                button: "Back to Home"
            }
        }
        if (lang === 'zh') {
            this.notFoundData = {
                title: "页面未找到",
                button: "返回首页"
            }
        }
    },
}
</script>

<style scoped>
    .not-found-header {
        width: 100%;
        position: relative;
    }

    .not-found-header > svg {
        width: 100%;
        /* margin-top: 170px; */
        position: absolute;

    }

    .lines {
        position: absolute;
        top: 0;
    }

    .cut {
        position: absolute;
        bottom: 0;
        left: 0;
        fill: rgba(0,144,255,1);
    }

    .not-found-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 170px;
        padding-bottom: 300px;
    }

    .not-found-content > img {
        width: 100%;
        max-width: 500px;
        margin-right: 138px;
    }

    .not-found-content > div {
        font-size:50px;
        font-family:ArialMT;
        color:rgba(13,14,40,1);
        line-height:55px;
        text-align: center;
    }

    .not-found-content > div > p {
        margin: 50px 0;
    }

    .button {
        width:170px;
        height:50px;
        background:linear-gradient(206deg,rgba(0,196,255,1) 0%,rgba(0,145,255,1) 100%);
        box-shadow:0px 7px 17px 0px rgba(0,145,255,0.37),0px 2px 4px 0px rgba(0,0,0,0.04);
        border-radius:20px;
        font-size:14px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        line-height: 50px;
        letter-spacing:1px;
        text-shadow:0px 7px 17px rgba(0,145,255,0.37);
        margin: 0 auto;
    }

    .button a {
        color:rgba(255,255,255,1);
    }


    @media screen and (max-width: 1280px) {
        .not-found-content {
            flex-wrap: wrap;
        }

        .not-found-content > img {
            margin: 0;
            margin-bottom: 50px;
        }
    }

</style>