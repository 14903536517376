import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import notFound from '../views/notFound.vue'
import release from '../views/releases/v2-9-2-5-2022-02-25'
import releasesRoutes from './releasesRoutes'

Vue.use(VueRouter)
Vue.use(Meta)

var routes = [
  {
    path: '/',
    name: 'home',
    component: release
  },
  {
    path: '/docs',
    name: 'home',
    component: release
  },
  {
    path: '/release',
    name: 'release',
    component: release
  },
  {
    path: '/404',
    name: 'notFound',
    component: notFound
  }
]

routes = routes.concat(releasesRoutes)

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) { 
    from.name ? next({
      name: from.name
    }) : next('/404'); 
  } else {
    next();
  }
});

export default router