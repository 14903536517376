<template>
  <div id="app">
    <Header />
    <HeaderMobile />

    <router-view />

    <Footer v-if="showMobile"/>
    <FooterMobile v-if="!showMobile"/>
  </div>
</template>

<script>
import Header from './components/header-pc';
import Footer from './components/footer';

import HeaderMobile from './components/header-mobile';
import FooterMobile from './components/footer-mobile'
export default {
	name: "App",
	components: {
		Header,
		HeaderMobile,
		Footer,
		FooterMobile
	},
	data: function() {
        return {
            showMobile: true,
        }
    },
    created: function() {
        this.windowScreenChange();
    },

    mounted() {
      window.onresize = () => {
          this.windowScreenChange();
      };
    },

    methods: {
        windowScreenChange: function() {
            var docWidth = document.body.clientWidth;
            this.showMobile = true;
            if (docWidth < 1280 || docWidth == 1280) {
                this.showMobile = false;
            }
        }
    },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: ff-real-headline-pro;
}

p {
  margin: 0;
  padding: 0;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.clear-float::before, .clear-float::after {
    content: '';
    display: block;
    clear: both;
    zoom: 1;
}

@media screen and (max-width: 1280px) {
    #app {
        padding-top: 0;
    }
}

</style>
