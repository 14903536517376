<template>
    <div class="header-box header-box-pc">
        <!-- <transition-group name="fade" mode="in-out"> -->
            <div class="promotion-banner promotion-banner-signup" v-if="showBanner">
                <div class="banner-content">
                    <p>{{ headerNav.banner.title }}</p>
                    <div class="promotion-button">
                        <div class="learn-more"><a :href="headerNav.banner.link" target="_blank">{{ headerNav.banner.linkTitle }}</a></div>
                    </div>
                </div>
            </div>
             <div class="header-outer" v-bind:class="headerOuterBackground" v-if="screenShow" key="header-outer">
                <ul>
                    <li>
                        <a class="st-name-logo" href="/">
                            <img :src="'/static/images/header/' + streamnativeLogo + '.svg'" alt="">
                            <span class="st-name">StreamNative</span>
                        </a>
                    </li>
                </ul>

                <ul class="header-menu">
                    <li 
                        v-for="item in headerNav.menu" 
                        :key="item.key"  
                        @mouseenter="showFolder(item)" 
                        @mouseleave="hideFolder(item)">
                        <a>{{ item.nav }}</a>
                        <div class="our-cloud cloud-fold" v-if="item.showFold">
                            <ul>
                                <li v-for="i in item.subMenu" :key="i.key">
                                    <span v-if="!i.routerLink && !i.externalLink" class="about-pulsar-title">{{ i.title }}</span>
                                    <router-link 
                                        class="about-pulsar-text"
                                        v-if="i.routerLink && !i.externalLink"
                                        :to="'/' + $lang + i.routerLink"
                                        :class="i.class">
                                        {{ i.title }}
                                    </router-link>
                                    <a 
                                        class="about-pulsar-text"
                                        target="_blank"
                                        v-if="!i.routerLink && i.externalLink" 
                                        :href="i.externalLink"
                                        :class="i.class">
                                        {{ i.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>

                <ul class="header-link">
                    <li>
                        <a :href="'https://streamnative.io/' + $lang + '/contact'">{{ headerNav.contactUs }}</a>
                    </li>
                    <li>
                        <a class="border-box" :href="headerNav.signUpLink" target="_blank" >{{ headerNav.signUp }}</a>
                    </li>
                    <li v-show="headerSearch">
                        <div class="light-nav-button"><a :href="headerNav.loginLink" target="_blank">{{ headerNav.login }}</a></div>
                    </li>
                    <li>
                        <i v-show="headerSearch" class="el-icon-search search" @click="handleChangeSearch"></i>
                        <input 
                            type="text" 
                            placeholder="Search" 
                            class="search-inp-box" 
                            id="search_input"
                            v-show="!headerSearch" 
                            :value="searchValue" 
                            @blur="headerSearch = true">
                    </li>
                    <li>
                        <el-dropdown trigger="click" @command="handleCommandLanguage">
                            <span class="el-dropdown-link">
                                {{ indexLang }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in languageList" :key="item.command" :command="item.command">
                                    <span class="language-icon">
                                        <img :src="item.icon" alt="">
                                        {{ item.label }}
                                    </span>
                                </el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import 'docsearch.js/dist/cdn/docsearch.min.css'
import docsearch from 'docsearch.js'
import headerEnNav from '@/data/en/header/header.json';
import headerZhNav from '@/data/zh/header/header.json';
export default {
    name: 'headerBox',
    data() {
        return {
            showCloudFold: true,
            showProductFold: false,
            showLearnFold: false,
            showDocsFold: false,
            menuShow: true,
            aboutPulsar: false,
            ourCompany: false,
            developers: false,
            headerSearch: true,
            searchValue: '',
            screenShow: true,
            mediaHeaderFold: false,
            developersNav: false,
            companyNav: false,
            productsNav: false,
            searchShow: false,
            streamnativeLogo: 'streamnative-dark',
            box: null,
            headerOuterBackground: {
                'header-outer-light-background': true,
                'header-outer-dark-background': false,
                'bg-transparent-dark-font': false
            },
            leftUl: {
                'left-light-ul': true,
                'left-dark-ul': false
            },
            rightUl: {
                'right-light-ul': true,
                'right-dark-ul': false
            },
            downloadBackground: {
                'light-nav-button': false,
                'dark-nav-button': true
            },
            storiesList: [],
            showBanner: false,
            languageList: [
                {
                    command: "en",
                    label: "English",
                    icon: "/static/images/header/en.svg"
                },
                {
                    command: "zh",
                    label: "简体中文",
                    icon: "/static/images/header/zh.svg"
                }
            ],
            
            indexLang: 'EN',
            // successStories: successStoriesEn,
            headerNav: headerEnNav,
        }
    },
    created() {
        window.onresize = (e) => {
            let clientWidth = document.body.clientWidth;
            if (clientWidth < 1194) {
                this.screenShow = false;
            } else {
                this.screenShow = true;
            }
            
        }
        let currentPath = this.$route.path.split('/')
        let lang = 'en'
        if (currentPath.length >= 2
            && (currentPath[1] === 'zh' || currentPath[1] === 'en')) {
            sessionStorage.setItem('lang', currentPath[1])
            lang = currentPath[1]
        }
        if (this.$lang === 'zh' || lang === 'zh') {
            this.indexLang = '简体中文'
            this.headerNav = headerZhNav
        } else {
            this.indexLang = 'English'
            this.headerNav = headerEnNav
        }
    },
    mounted() {
        this.headerOuterBackground['header-outer-light-background'] = true
        this.headerOuterBackground['header-outer-dark-background'] = false
        this.streamnativeLogo = 'streamnative-dark';
    },
    methods: {
        handleChangeAboutPulsar: function() {
            if (this.ourCompany || this.developers) return;
            this.aboutPulsar = !this.aboutPulsar;
        },
        handleChangeOurCompany: function() {
            if (this.aboutPulsar || this.developers) return;
            this.ourCompany = !this.ourCompany;
        },
        handleChangeDevelopers: function() {
            if (this.aboutPulsar || this.ourCompany) return;
            this.developers = !this.developers;
        },
        handleChangeSearch: function() {
            this.headerSearch = false;
            
            this.$nextTick(() => {
                
                docsearch({
                    apiKey: 'f138556e359e62a59c4f288f8801826a',
                    indexName: 'streamnative',
                    inputSelector: '#search_input'
                })
                document.getElementById('search_input').focus();
            });
        },
        
        handleChangeMediaHeaderFold: function() {
            this.mediaHeaderFold = !this.mediaHeaderFold;
            this.menuShow = !this.menuShow;
        },
        handleChangeMediaSearch: function() {
            this.searchShow = !this.searchShow;
        },
        handleCommandLanguage(data) {
            this.indexLang = this.languageList.find(ele => ele.command === data).label
            let pathList = this.$route.path.split('/')
            sessionStorage.setItem('lang', data)
            if (this.$route.path.indexOf('/platform') < 0) {
                if (pathList.length > 2) {
                    if (this.$route.path.indexOf('/en/') >= 0 && data == 'zh') {
                        window.location.href = this.$route.path.replace('/en/', '/zh/')
                    } else if (this.$route.path.indexOf('/en/') >= 0 && data == 'en') {
                        return
                    } else if (this.$route.path.indexOf('/zh/') >= 0 && data == 'en')  {
                        window.location.href = this.$route.path.replace('/zh/', '/en/')
                    } else if (this.$route.path.indexOf('/zh/') >= 0 && data == 'zh') {
                        return
                    } else {
                         window.location.href = '/' + data + this.$route.path
                    }
                } else {
                    if (data === 'zh') {
                        window.location.href = '/zh/cloud/stable/overview'
                    } else {
                        this.$router.go(0)
                    }
                }
            }
        },
        showFolder(data) {
            data.showFold = true
        },
        hideFolder(data) {
            data.showFold = false
        }
    },
    destroyed () {
        if (this.box !== null) {
            window.removeEventListener('scroll', this.handleScroll)
            
        }
    }
}
</script>

<style scoped>
  .promotion-banner {
        width: 100%;
        padding: 5px 0;
    }
    .promotion-banner-signup {
        background: #8160ff;
    }
    .promotion-banner-learn-more {
        background: #0091ff;
    }
    .promotion-banner p {
        text-align: center;
        font-size: 12px;
        color: #ffffff;
    }
    .promotion-button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .banner-content {
        width: 70%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        margin: 0 auto;
    }
    .promotion-button > div {
        width: 122px;
        height: 26px;
        border-radius: 20px;
        margin-right: 5px;
    }
    .learn-more {
        background: #0d0e28;
        text-align: center;
    }
    .learn-more a {
        color: #ffffff;
        font-size: 12px;
        line-height: 24px;
        font-weight: bold;
    }
    .search-inp-box {
        height: 50;
        border-radius: 20px;
        border: none;
        padding: 15px 25px;
        box-sizing: border-box;
        box-shadow: 0 8px 24px 0 rgba(0, 145, 255, 0.15);
    }
    .search-inp-box:focus {
        border: none;
        outline: none;
        box-shadow: 0 8px 24px 0 rgba(0, 145, 255, 0.15);
    }
</style>

<style scoped>
.header-box {
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  min-width: 1280px;
}
.header-outer {
  padding: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}
.st-name-logo {
  display: flex;
  align-items: center;
}
.st-name-logo img {
    height: 45px;
}
.st-name-logo span {
  margin-left: 10px;
  color: #ffffff;
}
.header-outer > ul {
  display: flex;
}
.header-menu {
  flex: 1;
  justify-content: flex-start;
}
.header-menu > li,
.header-link > li {
  display: flex;
  align-items: center;
}
.header-menu > li {
  padding: 10px 30px;
  box-sizing: border-box;
  position: relative;
}
.header-menu > li > a,
.header-link > li > a,
.header-link .el-dropdown-link {
  color: #ffffff;
  height: 100%;
  line-height: 45px;
}
.header-link > li {
  margin: 0 20px;
}
.header-link > li > a {
  font-weight: normal;
}
.light-nav-button > a {
  padding: 5px 40px;
  background: #0091ff;
  color: #ffffff;
}
.search {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.cloud-fold-box {
    position: relative;
    cursor: pointer;
}
.about-pulsar-text > a {
  color: #000000;
}
.about-pulsar-text > a:hover {
  color: #0091FF;
}
.our-company, .our-cloud {
    width: 255px;
    /* height: 240px; */
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 70%;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    
}
.our-company:before, .our-cloud:before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-style: solid;
    border-width: 15px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #ffffff;
    position: absolute;
    top: -30px;
    transform: translate(50%,0);
}
.our-company::before {
    left: 30px;
}
.our-cloud:before {
    left: 15px;
}
.about-pulsar-title {
    color: #707193;
    font-size: 10px;
    line-height: 25px;
}
.about-pulsar-learn::before {
    background-image: url('/static/images/header/learn.svg');
    background-size: 20px ;
    background-repeat: no-repeat;
}
.about-pulsar-blog::before {
    background-image: url('/static/images/header/menu-icon/blog.svg');
    background-size: 20px ;
}
.about-pulsar-events::before {
    background-image: url('/static/images/header/menu-icon/events.svg');
    background-size: 20px ;
}
.about-pulsar-doc::before {
    background-image: url('/static/images/header/ic-book.svg');
    background-size: 20px ;
}
.about-pulsar-github::before {
    background-image: url('/static/images/header/octicons-mark-github.svg');
    background-size: 20px ;
}
.about-pulsar-contact::before {
    background-image: url('/static/images/header/ic-mail.svg');
    background-size: 20px ;
}
.about-pulsar-document::before {
    background-image: url('/static/images/header/cloud-host.png');
    background-size: 20px ;
}
.about-pulsar-weekly::before {
    background-image: url('/static/images/header/ic-rotate-right.svg');
    background-size: 20px ;
}
.about-pulsar-summit::before {
    background-image: url('/static/images/header/pulsar-summit.png');
    background-size: 20px ;
}
.about-pulsar-hub::before {
    background-image: url('/static/images/header/menu-icon/hub.svg');
    background-size: 20px ;
}
.about-pulsar-history::before {
    background-image: url('/static/images/header/SN.png');
    background-size: 20px ;
}
.about-pulsar-careers::before {
    background-image: url('/static/images/header/ic-people.svg');
    background-size: 20px ;
}
.about-pulsar-stories::before {
    background-image: url('/static/images/header/menu-icon/success-stories.svg');
    background-size: 20px ;
}
.about-pulsar-apache::before {
    background-image: url('/static/images/header/ic-open.svg');
    background-size: 20px ;
}
.about-pulsar-platform::before {
    background-image: url('/static/images/header/menu-icon/platform.svg');
    background-size: 20px ;
}
.about-pulsar-academy::before {
    background-image: url('/static/images/header/menu-icon/academy.svg');
    background-size: 20px ;
}
.about-pulsar-resource::before {
    background-image: url('/static/images/header/menu-icon/resource.svg');
    background-size: 20px ;
}
.about-pulsar-services::before {
    background-image: url('/static/images/header/menu-icon/services.svg');
    background-size: 20px ;
}
.about-pulsar-pulsar::before {
    background-image: url('/static/images/header/menu-icon/pulsar.svg');
    background-size: 20px ;
}
.about-pulsar-text {
    font-size: 15px;
    line-height: 30px;
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #31324c;
}
.about-pulsar-text::before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background-repeat: no-repeat;
}
.cloud-host::before {
    background-image: url('/static/images/header/cloud-host.png');
    background-size: 20px ;
    background-repeat: no-repeat;
}
.cloud-managed::before {
    background-image: url('/static/images/header/cloud-managed.png');
    background-size: 20px ;
    background-repeat: no-repeat;
}
.support::before {
    background-image: url('/static/images/support/development_icon.svg');
    background-size: 35px ;
    background-repeat: no-repeat;
    background-position: center;
}
.faq::before {
    background-image: url('/static/images/support/faq_icon.svg');
    background-size: 20px ;
    background-repeat: no-repeat;
    background-position: center;
}
</style>

<style scoped>
.header-outer-light-background {
    background: #ffffff;
}
.header-outer-dark-background {
    background: transparent;
}
.header-outer-light-background > .header-menu > li > a,
.header-outer-light-background > .header-link > li > a,
.header-outer-light-background .st-name-logo span,
.header-outer-light-background .search,
.bg-transparent-dark-font > .header-menu > li > a,
.bg-transparent-dark-font > .header-link > li > a,
.bg-transparent-dark-font .st-name-logo span,
.bg-transparent-dark-font .search,
.header-outer-light-background .el-dropdown-link,
.bg-transparent-dark-font .el-dropdown-link {
    color: #000000;
}
.select-box {
    background: #F7F8FF;
    padding: 0 10%;
}
.select-box a {
    margin-right: 55px;
    font-size: 22px;
    line-height: 44px;
    color: #8785AB;
}
.select-box .select-isActive {
    color: #1C8DFF;
    border-bottom: 2px solid #1C8DFF;
}
.el-dropdown-link {
    font-size: 18px;
    color: #ffffff;
}
.header-outer-light-background .el-dropdown-link {
    color: #000000;
}
.language-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.language-icon > img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
@media screen and (max-width: 1440px) {
    .light-nav-button > a {
        padding: 5px 30px;
    }
    .header-link > li {
        margin: 0 10px;
    }
    .header-menu > li {
        padding: 10px 25px;
    }
}
@media screen and (max-width: 1280px) {
   .header-box-pc {
       display: none;
   } 
   .header-mobile-box {
       display: block;
   }
   
   .light-nav-button > a {
        padding: 5px 30px;
    }
    .header-link > li {
        margin: 0 10px;
    }
}
</style>