/*
 * @Author: your name
 * @Date: 2020-09-23 12:03:28
 * @LastEditTime: 2020-09-23 23:22:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /streamnative.io/document/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAnalytics from 'vue-analytics';
Vue.use(ElementUI);

// Configuration VueAnalytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS === 'true') {
  Vue.use(VueAnalytics, {
    id: 'UA-150699026-1',
    router
  });
}

import './assets/css/style.css';

// console.log(sessionStorage.getItem('lang'))
Vue.prototype.$lang = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'en'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
