<template>
    <div class="header-mobile-box">
        <div class="mobile-box" ref="headerMobileBox">
            <div class="mobile-nav-box">
                <a class="st-name-logo" href="/">
                    <img :src="'/static/images/header/' + streamnativeLogo + '.png'" alt="">
                </a>
                <div>
                    <div class="menu-button"  >
                        <svg v-if="!showClose" @click="openFoldList" class="menu-icon" :class=" scrollMenuIcon ? 'menu-icon-dark' : '' " t="1584952998205" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2637" width="200" height="200"><path d="M128 768l768 0 0-85.333333L128 682.666667 128 768zM128 554.666667l768 0 0-85.333333L128 469.333333 128 554.666667zM128 256l0 85.333333 768 0L896 256 128 256z" p-id="2638"></path></svg>
                        <i class="el-icon-close close-button" v-if="showClose" @click="closeFoldList"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-fold" v-if="showFold">
            <div class="fold-list">
                <ul>
                    <div v-for="item in headerNav.menu" 
                        :key="item.key">
                        <li @click="item.showFold = !item.showFold">
                            <div >
                                <p>{{ item.nav }}</p>
                                <i :class=" item.showFold ? 'el-icon-arrow-down' : 'el-icon-arrow-right' "></i>
                            </div>
                        </li>
                        <div class="developers-fold" v-if="item.showFold">
                            <ul v-for="i in item.subMenu" :key="i.key">
                                <li class="about-pulsar-text"
                                    v-if="i.routerLink"
                                    :class="i.class">
                                    <a :href="i.routerLink">{{ i.title }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <a class="download-button" :href="headerNav.downloadLink">{{ headerNav.download }}</a>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import headerEnNav from '@/data/en/header/header-mobile.json';
import headerZhNav from '@/data/zh/header/header-mobile.json';
export default {
    name: 'header-mobile',
    data() {
        return {
            showBanner: true,
            streamnativeLogo: 'mobile-logo-dark',
            scrollMenuIcon: false,
            showFold: false,
            showClose: false,
            showDevelopers: false,
            showCompany: false,
            showProducts: false,
            showApachePulsar: false,
            indexLang: 'English',
            headerNav: headerEnNav,
            headerOuterBackground: {
                'header-outer-light-background': true,
                'header-outer-dark-background': false,
                'bg-transparent-dark-font': false
            },
        }
    },
    
    watch: {
        showFold: function(val){
            this.showBanner = !val;
        }    
    },
    mounted: function() {
        window.addEventListener('scroll', this.handleScroll);
        let currentPath = this.$route.path.split('/')
        let lang = 'en'
        if (currentPath.length >= 2
            && (currentPath[1] === 'zh' || currentPath[1] === 'en')) {
            sessionStorage.setItem('lang', currentPath[1])
            lang = currentPath[1]
        }
        if (this.$lang === 'zh' || lang === 'zh') {
            this.indexLang = '简体中文'
            this.headerNav = headerZhNav
        } else {
            this.indexLang = 'English'
            this.headerNav = headerEnNav
        }
        let headerMobileBox = this.$refs.headerMobileBox;
        this.scrollMenuIcon = true;
        headerMobileBox.style.background = '#FFFFFF';
        this.headerOuterBackground['header-outer-light-background'] = true
        this.headerOuterBackground['header-outer-dark-background'] = false
        this.streamnativeLogo = 'mobile-logo-dark';
    },
    methods: {
        openFoldList: function() {
            this.showFold = true;
            this.showClose = true;
            this.streamnativeLogo = 'mobile-logo-dark';
            this.$emit('showFold', this.showFold);
        },
        closeFoldList: function() {
            this.showFold = false;
            this.showClose = false;
            this.streamnativeLogo = 'mobile-logo-dark';
            this.$emit('showFold', this.showFold);
        },
        handleScroll: function() {
            let headerMobileBox = this.$refs.headerMobileBox;
            var scrollTop = document.documentElement.scrollTop;
            if (this.showFold) return;
        }
    },
    destroyed () {
        if (this.box !== null) {
            window.removeEventListener('scroll', this.handleScroll)
        }
    },
}
</script>

<style scoped>
    .header-mobile-box {
        display: none;
    }
    .mobile-box {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        /* background: #ffffff; */
        display: block;
    }
    .mobile-nav-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        background: transparent;
    }
    .st-name-logo img {
        width: 100px;
        vertical-align: middle;
    }
    .menu-icon {
        width: 25px;
        height: 25px;
        fill: #ffffff;
    }
    .menu-icon-dark {
        fill: #000000;
    }
    .close-button {
        font-size: 25px;
        color: #000000;
    }
    .mobile-fold {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(13, 14, 40);
        z-index: 99;
        overflow-y: scroll;
    }
    .fold-list {
        width: 100%;
        background: #ffffff;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    .fold-list > ul {
        padding-top: 113px;
        border-top: 1px solid #ededf4;
    }
    .fold-list > ul > div > li {
        box-sizing: border-box;
        padding: 0 40px;
        padding-bottom: 10px;
        margin-top: 40px;
        border-bottom: 1px solid #ededf4;
    }
    .fold-list > ul > div > li > div {
        font-size: 16px;
        color: #9696a9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 32px;
        padding: 0 10px;
        border-radius: 12px;
    }
    .download-button {
        height: 85px;
        width: 100%;
        border-radius: 30px;
        margin-top: 70px;
        background: #f7f8ff;
        color: #009dff;
        display: flex;
        align-items: center;
        padding: 0 40px;
        box-sizing: border-box;
    }
    .show-developers {
        border-radius: 12px;
        background: #ededf4;
    }
    .developers-fold {
        width: 100%;
        padding: 0px 50px 0px;
        box-sizing: border-box;
        
    }
    .developers-fold > ul > li {
        font-size: 16px;
        color: #31324c;
        margin: 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .developers-fold > ul > li > img {
        height: 17px;
        margin-right: 20px;
    }
    
    .about-pulsar-text {
        color: 31324c;
        font-size: 15px;
        line-height: 30px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #31324c;
    }
    .about-pulsar-text::before {
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 20px;
        background-repeat: no-repeat;
    }
    .about-pulsar-learn::before {
        background-image: url('/static/images/header/learn.svg');
        background-size: 20px ;
        background-repeat: no-repeat;
    }
    .about-pulsar-blog::before {
        background-image: url('/static/images/header/ic-lightbulb-outline.svg');
        background-size: 20px ;
    }
    .about-pulsar-events::before {
        background-image: url('/static/images/header/ic-perm-contact-calendar.svg');
        background-size: 20px ;
    }
    .about-pulsar-doc::before {
        background-image: url('/static/images/header/ic-book.svg');
        background-size: 20px ;
    }
    .about-pulsar-github::before {
        background-image: url('/static/images/header/octicons-mark-github.svg');
        background-size: 20px ;
    }
    .about-pulsar-contact::before {
        background-image: url('/static/images/header/ic-mail.svg');
        background-size: 20px ;
    }
    .about-pulsar-document::before {
        background-image: url('/static/images/header/document.png');
        background-size: 20px ;
    }
    .about-pulsar-weekly::before {
        background-image: url('/static/images/header/ic-rotate-right.svg');
        background-size: 20px ;
    }
    .about-pulsar-summit::before {
        background-image: url('/static/images/header/pulsar-summit.png');
        background-size: 20px ;
    }
    .about-pulsar-hub::before {
        background-image: url('/static/images/header/pulsar-hub.svg');
        background-size: 20px ;
    }
    .about-pulsar-history::before {
        background-image: url('/static/images/header/SN.png');
        background-size: 20px ;
    }
    .about-pulsar-careers::before {
        background-image: url('/static/images/header/ic-people.svg');
        background-size: 20px ;
    }
    .about-pulsar-stories::before {
        background-image: url('/static/images/header/ic-comment.svg');
        background-size: 20px ;
    }
    .about-pulsar-apache::before {
        background-image: url('/static/images/header/ic-open.svg');
        background-size: 20px ;
    }
    .about-pulsar-services::before {
        background-image: url('/static/images/header/menu-icon/services.svg');
        background-size: 20px ;
    }
    .about-pulsar-academy::before {
        background-image: url('/static/images/header/menu-icon/academy.svg');
        background-size: 20px ;
    }
    .about-pulsar-resource::before {
        background-image: url('/static/images/header/menu-icon/resource.svg');
        background-size: 20px ;
    }
    .about-pulsar-pulsar::before {
        background-image: url('/static/images/header/menu-icon/pulsar.svg');
        background-size: 20px ;
    }
    /* .promotion-banner {
        width: 100%;
        padding: 10px 0;
    } */
    
    .promotion-banner-mobile-signup {
        background: #8160ff;
    }
    .promotion-banner-mobile-learn-more {
        background: #0091ff;
    }
    .promotion-banner-mobile {
        flex-direction: column;
        height: 100px;
        padding-top: 10px;
    }
    .promotion-banner-mobile p {
        text-align: center;
        font-size: 16px;
        color: #ffffff;
    }
    .promotion-button-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .promotion-button-mobile > div {
        width: 122px;
        height: 32px;
        border-radius: 20px;
        margin-right: 5px;
    }
    .promotion-button-mobile .learn-more {
        background: #0d0e28;
        text-align: center;
        margin-top: 15px;
    }
    .promotion-button-mobile .learn-more a {
        color: #ffffff;
        font-size: 12px;
        line-height: 32px;
        font-weight: bold;
    }
    .cloud-host::before {
        background-image: url('/static/images/header/cloud-host.png');
        background-size: 20px ;
        background-repeat: no-repeat;
    }
    .cloud-managed::before {
        background-image: url('/static/images/header/cloud-managed.png');
        background-size: 20px ;
        background-repeat: no-repeat;
    }
    .support::before {
        background-image: url('/static/images/support/development_icon.svg');
        background-size: 35px ;
        background-repeat: no-repeat;
        background-position: center;
    }
    .faq::before {
        background-image: url('/static/images/support/faq_icon.svg');
        background-size: 20px ;
        background-repeat: no-repeat;
        background-position: center;
    }
    @media screen and (max-width: 1280px) {
        .header-mobile-box {
            display: block;
        }
    }
</style>