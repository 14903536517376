<template>
    <div class="footer-box">
        <div class="footer-content">
            <el-row :gutter="50">
                <el-col :span="6">
                    <img class="footer-logo" src="../assets/streamnative_logo.png" alt="">
                    <img class="footer-hippa" src="../assets/img/HIPPA.png" alt="">
                </el-col>
                <el-col :span="8">
                    <el-row>
                        <el-col :span="12">
                            <ul class="footer-list">
                                <li 
                                    v-for="item in footerList.product" 
                                    :key="item.key">
                                    <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                                    <a v-if="item.routerLink" :href="item.routerLink">{{ item.title }}</a>
                                </li>
                            </ul>
                        </el-col>
                        <el-col :span="12">
                            <ul class="footer-list">
                                <li 
                                    v-for="item in footerList.project" 
                                    :key="item.key">
                                    <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                                    <a v-if="item.routerLink" :href="item.routerLink" :target=" item.key === 'bookkeeper' ? '_blank' : '' ">{{ item.title }}</a>
                                </li>
                                <!-- <li>
                                    <a href="/monthly">Pulsar Monthly</a>
                                </li>
                                <li>
                                    <a href="/releases">Releases</a>
                                </li> -->
                            </ul>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="6">
                    <el-row>
                        <el-col :span="12">
                            <ul class="footer-list">
                                <li 
                                    v-for="item in footerList.developer" 
                                    :key="item.key">
                                    <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                                    <a v-if="item.routerLink" :href="item.routerLink" :target=" item.key === 'pulsar-summit' ? '_blank' : '' ">{{ item.title }}</a>
                                </li>
                            </ul>
                        </el-col>
                        <el-col :span="12">
                            <ul class="footer-list">
                                <li 
                                    v-for="item in footerList.about" 
                                    :key="item.key">
                                    <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                                    <a v-if="item.routerLink" :href="item.routerLink">{{ item.title }}</a>
                                </li>
                            </ul>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="4">
                    <ul class="footer-list external-list">
                        <li 
                            v-for="item in footerList.connect" 
                            :key="item.key">
                            <span v-if="!item.routerLink" :class="item.class">{{ item.title }}</span>
                            <a v-if="item.routerLink" :href="item.routerLink" target="_blank">{{ item.title }}</a>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <div class="stay-update">
                <div class="hubspot-code">
                    <div class="footeHubspot">
                        <span ref="footerHubspot"></span>
                    </div>
                </div>
            </div>
            <div class="statement-box" v-html="footerList.copyRight.replace('YEAR', new Date().getFullYear())">
            </div>
        </div>
    </div>
</template>

<script>
import footerEnList from '@/data/en/footer/footer.json'
import footerZhList from '@/data/zh/footer/footer.json'
export default {
    name: "footerBox",
    data() {
        return {
            footerList: footerEnList
        }
    },
    created() {
        let currentPath = this.$route.path.split('/')
        let lang = 'en'
        if (currentPath.length >= 2
            && (currentPath[1] === 'zh' || currentPath[1] === 'en')) {
            sessionStorage.setItem('lang', currentPath[1])
            lang = currentPath[1]
        }
        if (this.$lang === 'zh' || lang === 'zh') {
            this.footerList = footerZhList
        } else {
            this.footerList = footerEnList
        }
    },
    mounted() {
        this.initHubspotScript(this.addHubspotCode)
    },
    methods: {
        initHubspotScript(callback) {
            let scriptTagHubspotJs = document.createElement('script')
            scriptTagHubspotJs.setAttribute('src', '//js.hsforms.net/forms/shell.js')
            scriptTagHubspotJs.setAttribute('type', 'text/javascript')
            scriptTagHubspotJs.setAttribute('charset', 'utf-8')
            scriptTagHubspotJs.onload = function(){
                callback();
            };
            document.head.appendChild(scriptTagHubspotJs)
            this.$refs.footerHubspot.insertAdjacentElement('beforeend', scriptTagHubspotJs)
        },
        addHubspotCode() {
            let hubspotCode = document.createElement('script')  
            hubspotCode.setAttribute('type', 'text/javascript')
            hubspotCode.setAttribute('defer', 'defer')
            let inlineScript = document.createTextNode('hbspt.forms.create({portalId: "6585952",formId: "5f649319-6bb9-4b52-963a-a9070bc42e77"});')
            hubspotCode.appendChild(inlineScript)
            this.$refs.footerHubspot.insertAdjacentElement('afterend', hubspotCode)
        }
    }
}
</script>

<style scoped>
    .footer-content ul li{
        list-style: none;
    }
    .footer-content {
        width: 100%;
        background: #0d0e28;
        padding: 100px 130px;
        box-sizing: border-box;
        min-width: 1280px;
    }
    .footer-logo {
        width: 170px;
        display: block;
    }
    .footer-hippa {
        width: 100px;
        margin-top: 30px;
    }
    .footer-list a {
        text-align: left;
        color: #656687;
        font-size: 16px;
        line-height: 32px;
        font-family: ff-real-text-pro;
    }
    .stay-update {
        width: 307px;
        /* height: 41px;
        border-radius: 10px;
        background-color: #31324c;
        line-height: 41px;
        color: #707193;
        padding: 0 18px;
        box-sizing: border-box;
        margin-top: 35px; */
    }
    
    .footer-title {
        font-size: 16px;
        font-family: ff-real-text-pro;
        font-weight: bold;
        line-height: 45px;
        color:rgba(168,168,201,1);
        opacity:1;
    }
    .external-list > li a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .external-list > li a::after {
        content: '';
        width: 15px;
        height: 15px;
        margin-left: 10px;
        background-image: url('/static/images/header/ic-open-footer.svg');
        background-size: 15px ;
    }
</style>

<style>
    .statement-box {
        margin-top: 10px;
    }
    .statement-box a{
        color: #656687;
        font-size: 16px;
        line-height: 32px;
        font-family: ff-real-text-pro;
    }
    .statement-box::before, .statement-box::after {
        content: '';
        clear: both;
        display: block;
        zoom: 1;
    }
    .statement-box span {
        margin-right: 20px;
        line-height: 40px;
        font-family: ff-real-text-pro;
    }
    .statement-text {
        float: right;
        color: #b5b5c6;
        font-size: 12px;
        line-height: 40px;
        font-family: ff-real-text-pro;
    }
</style>