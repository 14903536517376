
import releasesv293920220922 from '../views/releases/v2-9-3-9-2022-09-22'

import releasesv293820220919 from '../views/releases/v2-9-3-8-2022-09-19'

import releasesv293620220830 from '../views/releases/v2-9-3-6-2022-08-30'

import releasesv293520220819 from '../views/releases/v2-9-3-5-2022-08-19'

import releasesv293420220811 from '../views/releases/v2-9-3-4-2022-08-11'

import releasesv293320220726 from '../views/releases/v2-9-3-3-2022-07-26'

import releasesv293220220719 from '../views/releases/v2-9-3-2-2022-07-19'

import releasesv2931320221009 from '../views/releases/v2-9-3-13-2022-10-09'

import releasesv2931220221006 from '../views/releases/v2-9-3-12-2022-10-06'

import releasesv2931120220928 from '../views/releases/v2-9-3-11-2022-09-28'

import releasesv2931020220923 from '../views/releases/v2-9-3-10-2022-09-23'

import releasesv292920220322 from '../views/releases/v2-9-2-9-2022-03-22'

import releasesv292820220315 from '../views/releases/v2-9-2-8-2022-03-15'

import releasesv292720220312 from '../views/releases/v2-9-2-7-2022-03-12'

import releasesv292520220303 from '../views/releases/v2-9-2-5-2022-03-03'

import releasesv292520220225 from '../views/releases/v2-9-2-5-2022-02-25'

import releasesv2922420220711 from '../views/releases/v2-9-2-24-2022-07-11'

import releasesv2922320220704 from '../views/releases/v2-9-2-23-2022-07-04'

import releasesv2922220220622 from '../views/releases/v2-9-2-22-2022-06-22'

import releasesv2922020220613 from '../views/releases/v2-9-2-20-2022-06-13'

import releasesv2921920220607 from '../views/releases/v2-9-2-19-2022-06-07'

import releasesv2921820220530 from '../views/releases/v2-9-2-18-2022-05-30'

import releasesv2921720220514 from '../views/releases/v2-9-2-17-2022-05-14'

import releasesv2921620220511 from '../views/releases/v2-9-2-16-2022-05-11'

import releasesv2921320220421 from '../views/releases/v2-9-2-13-2022-04-21'

import releasesv2921220220404 from '../views/releases/v2-9-2-12-2022-04-04'

import releasesv2921120220403 from '../views/releases/v2-9-2-11-2022-04-03'

import releasesv284120220920 from '../views/releases/v2-8-4-1-2022-09-20'

import releasesv283620220805 from '../views/releases/v2-8-3-6-2022-08-05'

import releasesv283420220613 from '../views/releases/v2-8-3-4-2022-06-13'

import releasesv283320220525 from '../views/releases/v2-8-3-3-2022-05-25'

import releasesv283120220416 from '../views/releases/v2-8-3-1-2022-04-16'

import releasesv2821520220322 from '../views/releases/v2-8-2-15-2022-03-22'

import releasesv2821420220322 from '../views/releases/v2-8-2-14-2022-03-22'

import releasesv2821320220314 from '../views/releases/v2-8-2-13-2022-03-14'

import releasesv2821220220312 from '../views/releases/v2-8-2-12-2022-03-12'

import releasesv2821220220310 from '../views/releases/v2-8-2-12-2022-03-10'

import releasesv2821120220312 from '../views/releases/v2-8-2-11-2022-03-12'

import releasesv274820220804 from '../views/releases/v2-7-4-8-2022-08-04'

import releasesv2101920221008 from '../views/releases/v2-10-1-9-2022-10-08'

import releasesv2101820220915 from '../views/releases/v2-10-1-8-2022-09-15'

import releasesv2101720220829 from '../views/releases/v2-10-1-7-2022-08-29'

import releasesv2101420220727 from '../views/releases/v2-10-1-4-2022-07-27'

import releasesv2101220220711 from '../views/releases/v2-10-1-2-2022-07-11'

import releasesv2101120220629 from '../views/releases/v2-10-1-1-2022-06-29'

import releasesv2100620220616 from '../views/releases/v2-10-0-6-2022-06-16'

import releasesv2100520220614 from '../views/releases/v2-10-0-5-2022-06-14'

import releasesv2100420220526 from '../views/releases/v2-10-0-4-2022-05-26'

import releasesv2100320220514 from '../views/releases/v2-10-0-3-2022-05-14'

const routes = [
    
    {
        path: '/releases/v2-9-3-9-2022-09-22',
        name: 'releasesv293920220922',
        component: releasesv293920220922
    },
    
    {
        path: '/releases/v2-9-3-8-2022-09-19',
        name: 'releasesv293820220919',
        component: releasesv293820220919
    },
    
    {
        path: '/releases/v2-9-3-6-2022-08-30',
        name: 'releasesv293620220830',
        component: releasesv293620220830
    },
    
    {
        path: '/releases/v2-9-3-5-2022-08-19',
        name: 'releasesv293520220819',
        component: releasesv293520220819
    },
    
    {
        path: '/releases/v2-9-3-4-2022-08-11',
        name: 'releasesv293420220811',
        component: releasesv293420220811
    },
    
    {
        path: '/releases/v2-9-3-3-2022-07-26',
        name: 'releasesv293320220726',
        component: releasesv293320220726
    },
    
    {
        path: '/releases/v2-9-3-2-2022-07-19',
        name: 'releasesv293220220719',
        component: releasesv293220220719
    },
    
    {
        path: '/releases/v2-9-3-13-2022-10-09',
        name: 'releasesv2931320221009',
        component: releasesv2931320221009
    },
    
    {
        path: '/releases/v2-9-3-12-2022-10-06',
        name: 'releasesv2931220221006',
        component: releasesv2931220221006
    },
    
    {
        path: '/releases/v2-9-3-11-2022-09-28',
        name: 'releasesv2931120220928',
        component: releasesv2931120220928
    },
    
    {
        path: '/releases/v2-9-3-10-2022-09-23',
        name: 'releasesv2931020220923',
        component: releasesv2931020220923
    },
    
    {
        path: '/releases/v2-9-2-9-2022-03-22',
        name: 'releasesv292920220322',
        component: releasesv292920220322
    },
    
    {
        path: '/releases/v2-9-2-8-2022-03-15',
        name: 'releasesv292820220315',
        component: releasesv292820220315
    },
    
    {
        path: '/releases/v2-9-2-7-2022-03-12',
        name: 'releasesv292720220312',
        component: releasesv292720220312
    },
    
    {
        path: '/releases/v2-9-2-5-2022-03-03',
        name: 'releasesv292520220303',
        component: releasesv292520220303
    },
    
    {
        path: '/releases/v2-9-2-5-2022-02-25',
        name: 'releasesv292520220225',
        component: releasesv292520220225
    },
    
    {
        path: '/releases/v2-9-2-24-2022-07-11',
        name: 'releasesv2922420220711',
        component: releasesv2922420220711
    },
    
    {
        path: '/releases/v2-9-2-23-2022-07-04',
        name: 'releasesv2922320220704',
        component: releasesv2922320220704
    },
    
    {
        path: '/releases/v2-9-2-22-2022-06-22',
        name: 'releasesv2922220220622',
        component: releasesv2922220220622
    },
    
    {
        path: '/releases/v2-9-2-20-2022-06-13',
        name: 'releasesv2922020220613',
        component: releasesv2922020220613
    },
    
    {
        path: '/releases/v2-9-2-19-2022-06-07',
        name: 'releasesv2921920220607',
        component: releasesv2921920220607
    },
    
    {
        path: '/releases/v2-9-2-18-2022-05-30',
        name: 'releasesv2921820220530',
        component: releasesv2921820220530
    },
    
    {
        path: '/releases/v2-9-2-17-2022-05-14',
        name: 'releasesv2921720220514',
        component: releasesv2921720220514
    },
    
    {
        path: '/releases/v2-9-2-16-2022-05-11',
        name: 'releasesv2921620220511',
        component: releasesv2921620220511
    },
    
    {
        path: '/releases/v2-9-2-13-2022-04-21',
        name: 'releasesv2921320220421',
        component: releasesv2921320220421
    },
    
    {
        path: '/releases/v2-9-2-12-2022-04-04',
        name: 'releasesv2921220220404',
        component: releasesv2921220220404
    },
    
    {
        path: '/releases/v2-9-2-11-2022-04-03',
        name: 'releasesv2921120220403',
        component: releasesv2921120220403
    },
    
    {
        path: '/releases/v2-8-4-1-2022-09-20',
        name: 'releasesv284120220920',
        component: releasesv284120220920
    },
    
    {
        path: '/releases/v2-8-3-6-2022-08-05',
        name: 'releasesv283620220805',
        component: releasesv283620220805
    },
    
    {
        path: '/releases/v2-8-3-4-2022-06-13',
        name: 'releasesv283420220613',
        component: releasesv283420220613
    },
    
    {
        path: '/releases/v2-8-3-3-2022-05-25',
        name: 'releasesv283320220525',
        component: releasesv283320220525
    },
    
    {
        path: '/releases/v2-8-3-1-2022-04-16',
        name: 'releasesv283120220416',
        component: releasesv283120220416
    },
    
    {
        path: '/releases/v2-8-2-15-2022-03-22',
        name: 'releasesv2821520220322',
        component: releasesv2821520220322
    },
    
    {
        path: '/releases/v2-8-2-14-2022-03-22',
        name: 'releasesv2821420220322',
        component: releasesv2821420220322
    },
    
    {
        path: '/releases/v2-8-2-13-2022-03-14',
        name: 'releasesv2821320220314',
        component: releasesv2821320220314
    },
    
    {
        path: '/releases/v2-8-2-12-2022-03-12',
        name: 'releasesv2821220220312',
        component: releasesv2821220220312
    },
    
    {
        path: '/releases/v2-8-2-12-2022-03-10',
        name: 'releasesv2821220220310',
        component: releasesv2821220220310
    },
    
    {
        path: '/releases/v2-8-2-11-2022-03-12',
        name: 'releasesv2821120220312',
        component: releasesv2821120220312
    },
    
    {
        path: '/releases/v2-7-4-8-2022-08-04',
        name: 'releasesv274820220804',
        component: releasesv274820220804
    },
    
    {
        path: '/releases/v2-10-1-9-2022-10-08',
        name: 'releasesv2101920221008',
        component: releasesv2101920221008
    },
    
    {
        path: '/releases/v2-10-1-8-2022-09-15',
        name: 'releasesv2101820220915',
        component: releasesv2101820220915
    },
    
    {
        path: '/releases/v2-10-1-7-2022-08-29',
        name: 'releasesv2101720220829',
        component: releasesv2101720220829
    },
    
    {
        path: '/releases/v2-10-1-4-2022-07-27',
        name: 'releasesv2101420220727',
        component: releasesv2101420220727
    },
    
    {
        path: '/releases/v2-10-1-2-2022-07-11',
        name: 'releasesv2101220220711',
        component: releasesv2101220220711
    },
    
    {
        path: '/releases/v2-10-1-1-2022-06-29',
        name: 'releasesv2101120220629',
        component: releasesv2101120220629
    },
    
    {
        path: '/releases/v2-10-0-6-2022-06-16',
        name: 'releasesv2100620220616',
        component: releasesv2100620220616
    },
    
    {
        path: '/releases/v2-10-0-5-2022-06-14',
        name: 'releasesv2100520220614',
        component: releasesv2100520220614
    },
    
    {
        path: '/releases/v2-10-0-4-2022-05-26',
        name: 'releasesv2100420220526',
        component: releasesv2100420220526
    },
    
    {
        path: '/releases/v2-10-0-3-2022-05-14',
        name: 'releasesv2100320220514',
        component: releasesv2100320220514
    },
    
    {
        path: '/releases',
        name: 'releasesv293920220922-index',
        component: releasesv293920220922
    },
    
]

export default routes
